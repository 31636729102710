import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiAuthConnect} from "../common/api/auth/apiAuthConnect";
import {apiCountryList} from "../common/api/country/apiCountryList";
import {CountryInterface} from "../common/interfaces/CountryInterface";
import {apiOrganisationAddressTypeList} from "../common/api/organisation/address/apiOrganisationAddressTypeList";
import {AddressTypeInterface} from "../common/interfaces/AddressTypeInterface";
import {apiOrganisationUserGenderList} from "../common/api/organisation/user/apiOrganisationUserGenderList";
import {GenderInterface} from "../common/interfaces/GenderInterface";
import {apiOrganisationPassportTypeList} from "../common/api/organisation/passport/apiOrganisationPassportTypeList";
import {PassportTypeInterface} from "../common/interfaces/PassportTypeInterface";
import {apiMeasurementWeightTypes} from "../common/api/measurement/apiMeasurementWeightTypes";
import {WeightTypeInterface} from "../common/interfaces/WeightTypeInterface";
import {AmountTypeInterface} from "../common/interfaces/AmountTypeInterface";
import {apiMeasurementAmountTypes} from "../common/api/measurement/apiMeasurementAmountTypes";
import {apiMaterialGroupList} from "../common/api/material/apiMaterialGroupList";
import {MaterialGroupInterface} from "../common/interfaces/MaterialGroupInterface";
import {apiMaterialList} from "../common/api/material/apiMaterialList";
import {MaterialInterface} from "../common/interfaces/MaterialInterface";
import {apiOrganisationPassportList} from "../common/api/organisation/passport/apiOrganisationPassportList";
import {PassportInterface} from "../common/interfaces/PassportInterface";
import {UserInterface} from "../common/interfaces/UserInterface";
import {apiSdgGoalList} from "../common/api/sdgGoal/apiSdgGoalList";
import {SdgGoalInterface} from "../common/interfaces/SdgGoalInterface";
import {apiMaterialSets} from "../common/api/material/apiMaterialSets";
import {apiEndUseList} from "../common/api/endUse/apiEndUseList";
import {EndUseTreatment} from "../common/interfaces/EndUseTreatment";
import {MaterialSetInterface} from "../common/interfaces/MaterialSetInterface";
import {apiOrganisationDashboardTypeList} from "../common/api/organisation/dashboard/apiOrganisationDashboardTypeList";
import {apiOrganisationUserDetail} from "../common/api/organisation/user/apiOrganisationUserDetail";
import {apiOrganisationInfoDetail} from "../common/api/organisation/info/apiOrganisationInfoDetail";
import {OrganisationInfoInterface} from "../common/interfaces/OrganisationInfoInterface";

import {apiHowToList} from "../common/api/howTo/apiHowToList";
import {HowToInterface} from "../common/interfaces/HowToInterface";
import {LanguageInterface} from "../common/interfaces/LanguageInterface";
import {apiTranslationLanguages} from "../common/api/translation/apiTranslationLanguages";
import {apiTranslationList} from "../common/api/translation/apiTranslationList";
import {TranslationsInterface} from "../common/interfaces/TranslationsInterface";
import {ImpactFigureCalculationTypeInterface} from "../common/interfaces/ImpactFigureCalculationTypeInterface";
import {apiImpactCalculationTypeList} from "../common/api/impactCalculationType/apiImpactCalculationTypeList";

export interface RootInterface {
    authenticating: boolean,
    authToggle: boolean,
    userMe?: UserInterface,
    organisation?: OrganisationInfoInterface,
    options: {
        languages?: LanguageInterface[],
        countries?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data?: CountryInterface[]
        },
        addressTypes?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data?: AddressTypeInterface[]
        },
        genders?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data?: GenderInterface[]
        },
        passportTypes?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data?: PassportTypeInterface[]
        },
        dashboardTypes?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data?: WeightTypeInterface[]
        },
        weightTypes?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data?: WeightTypeInterface[]
        },
        amountTypes?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data?: AmountTypeInterface[]
        },
        materialGroup?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data: MaterialGroupInterface[]
        },
        materials?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            }
            data: MaterialInterface[]
        },
        sdgGoals?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            },
            data?: SdgGoalInterface[]
        },
        endUses?: {
            pages?: {
                limit?: number,
                total?: number,
                current?: number
            },
            data?: EndUseTreatment[]
        },
        materialSets?: MaterialSetInterface[]
        impactFigureCalculationTypes?: {
            data? : ImpactFigureCalculationTypeInterface[]
        },
    },
    passports?: {
        pages?: {
            limit?: number,
            total?: number,
            current?: number
        }
        data?: PassportInterface[]
    },
    translations?: TranslationsInterface
    howTo?: HowToInterface[]
    activeLanguage: string
    visibleEndUseSolution?: number
}

export const connect = createAsyncThunk(
    'root/connect',
    async (_) => {
        const response = await apiAuthConnect()
        localStorage.setItem('accessToken', response.data.items.accessToken.access_token)
        localStorage.setItem("refreshToken", response.data.items.accessToken.refresh_token);
    }
)

export const countryList = createAsyncThunk(
    'root/countryList',
    async (_) => {
        const response = await apiCountryList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const getTranslationList = createAsyncThunk(
    'root/getTranslationList',
    async (languageCode: string, {}) => {

        const responseTranslationList = await apiTranslationList(languageCode)

        return responseTranslationList?.data?.items.translations as TranslationsInterface
    }
)

export const getTranslationLanguages = createAsyncThunk(
    'root/getTranslationLanguages',
    async (_) => {
        const responseTranslationLanguages = await apiTranslationLanguages()

        return responseTranslationLanguages?.data?.items?.data
    }
)


export const addressTypeList = createAsyncThunk(
    'root/addressTypeList',
    async (_) => {
        const response = await apiOrganisationAddressTypeList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const genderList = createAsyncThunk(
    'root/genderList',
    async (_) => {
        const response = await apiOrganisationUserGenderList()

        if (response?.data?.items?.data) {
            return response.data.items
        }

    }
)

export const getUserMe = createAsyncThunk(
    'root/getUserMe',
    async (_) => {

        const user = JSON.parse(localStorage.getItem('user') + '');
        const response = await apiOrganisationUserDetail(user.id)

        if (response?.data?.items?.data) {
            return response.data.items.data
        }

    }
)

export const passportTypeList = createAsyncThunk(
    'root/passportTypeList',
    async (_) => {
        const response = await apiOrganisationPassportTypeList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const dashboardTypeList = createAsyncThunk(
    'root/dashboardTypeList',
    async (_) => {
        const response = await apiOrganisationDashboardTypeList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const weightTypeList = createAsyncThunk(
    'root/weightTypeList',
    async (_) => {
        const response = await apiMeasurementWeightTypes()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const amountTypeList = createAsyncThunk(
    'root/amountTypeList',
    async (_) => {
        const response = await apiMeasurementAmountTypes()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const materialGroupList = createAsyncThunk(
    'root/materialGroupList',
    async (_) => {
        const response = await apiMaterialGroupList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const materialList = createAsyncThunk(
    'root/materialList',
    async (_) => {
        const response = await apiMaterialList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const materialSets = createAsyncThunk(
    'root/materialSets',
    async (_) => {
        const response = await apiMaterialSets()
        if (response && response.data && response.data.items && response.data.items.sets) {
            return response.data.items.sets
        }
    }
)

export const sdgList = createAsyncThunk(
    'root/sdgList',
    async (_) => {
        const response = await apiSdgGoalList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const passportList = createAsyncThunk(
    'root/passportList',
    async (_) => {
        const response = await apiOrganisationPassportList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)


export const howToList = createAsyncThunk(
    'root/howToList',
    async (_) => {
        const response = await apiHowToList(9999)

        if (response?.data?.items?.data) {
            return response.data.items.data
        }
    }
)


export const endUseList = createAsyncThunk(
    'root/endUseList',
    async (_) => {
        const response = await apiEndUseList()
        if (response?.data?.items?.data) {
            return response.data.items
        }
    }
)

export const organisationDetail = createAsyncThunk(
    'root/organisationDetail',
    async () => {

        const organisationResponse = await apiOrganisationInfoDetail()
        return organisationResponse.data.items.info.organisation
    }
)

export const impactFigureCalculationTypeList = createAsyncThunk(
    'root/impactFigureCalculationTypeList',
    async () => {

        const response = await apiImpactCalculationTypeList()
        return response.data.items
    }
)


const initialState: RootInterface = {
    authenticating: true,
    authToggle: true,
    options: {},
    activeLanguage: !!localStorage.getItem('activeLanguage') ? localStorage.getItem('activeLanguage') + '' : 'EN',
    visibleEndUseSolution: 1
}

export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        authToggle: (state) => {
            state.authToggle = !state.authToggle
        },
        setActiveLanguage: (state, action: PayloadAction<string>) => {
            state.activeLanguage = action.payload
            localStorage.setItem('activeLanguage', action.payload)
        },
        changeEndUseSolution: (state, action: PayloadAction<number>) => {
            state.visibleEndUseSolution = action.payload
        }

    },
    extraReducers: (builder) => {
        builder.addCase(connect.pending, (state) => {
            state.authenticating = true
        })
        builder.addCase(connect.fulfilled, (state) => {
            state.authenticating = false
        })
        builder.addCase(getUserMe.fulfilled, (state, action: PayloadAction<RootInterface["userMe"]>) => {
            state.userMe = action.payload
        })
        builder.addCase(organisationDetail.fulfilled, (state, action: PayloadAction<RootInterface["organisation"]>) => {
            state.organisation = action.payload
        })
        builder.addCase(countryList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["countries"]>) => {
            state.options.countries = action.payload
        })
        builder.addCase(addressTypeList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["addressTypes"]>) => {
            state.options.addressTypes = action.payload
        })
        builder.addCase(genderList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["genders"]>) => {
            state.options.genders = action.payload
        })
        builder.addCase(passportTypeList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["passportTypes"]>) => {
            state.options.passportTypes = action.payload
        })
        builder.addCase(dashboardTypeList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["dashboardTypes"]>) => {
            state.options.dashboardTypes = action.payload
        })
        builder.addCase(weightTypeList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["weightTypes"]>) => {
            state.options.weightTypes = action.payload
        })
        builder.addCase(amountTypeList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["amountTypes"]>) => {
            state.options.amountTypes = action.payload
        })
        builder.addCase(materialGroupList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["materialGroup"]>) => {
            state.options.materialGroup = action.payload
        })
        builder.addCase(materialList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["materials"]>) => {
            state.options.materials = action.payload
        })
        builder.addCase(passportList.fulfilled, (state, action: PayloadAction<RootInterface["passports"]>) => {
            state.passports = action.payload
        })
        builder.addCase(sdgList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["sdgGoals"]>) => {
            state.options.sdgGoals = action.payload
        })
        builder.addCase(endUseList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["endUses"]>) => {
            state.options.endUses = action.payload
        })
        builder.addCase(materialSets.fulfilled, (state, action: PayloadAction<RootInterface["options"]["materialSets"]>) => {
            state.options.materialSets = action.payload
        })
        builder.addCase(impactFigureCalculationTypeList.fulfilled, (state, action: PayloadAction<RootInterface["options"]["impactFigureCalculationTypes"]>) => {
            state.options.impactFigureCalculationTypes = action.payload
        })
        builder.addCase(howToList.fulfilled, (state, action: PayloadAction<RootInterface["howTo"]>) => {
            state.howTo = action.payload
        })
        builder.addCase(getTranslationList.fulfilled, (state, action: PayloadAction<TranslationsInterface | undefined>) => {
            if (action.payload) {
                state.translations = action.payload
            }
        })
        builder.addCase(getTranslationLanguages.fulfilled, (state, action: PayloadAction<LanguageInterface[] | undefined>) => {
            if (action.payload) {
                state.options.languages = action.payload
            }
        })
    },
})

export const {authToggle, setActiveLanguage, changeEndUseSolution} = rootSlice.actions


export default rootSlice.reducer
