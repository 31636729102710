import {UploadedFileInterface} from "../../interfaces/UploadedFileInterface";

interface InputProps {
    label?: string,
    name?: string,
    required?: boolean,
    onChange: (value: FileList) => void,
    placeholder?: string,
    value?: FileList,
    uploadedFiles?: UploadedFileInterface[],
    modal?: string,
    loading?: boolean,
    defaultLabel?: boolean,
    accept: string,
    deleteAction: (value: UploadedFileInterface) => any,
    comment?: string
}

const FormFiles = (props: InputProps) => {


    return (
        <div className="c-form__group  is-multiple" style={{position: 'relative'}}>
            {props.defaultLabel && <label style={{
                margin: '0 0 -2rem 0'
            }}>{props.label}</label>}


            <label className={'inputfile'}>

                <div className={'content'}>

                    <input
                        accept={props.accept}
                        disabled={props.loading}
                        name={props.name}
                        multiple={true}
                        required={props.required}
                        type={'file'}
                        onChange={(e) => {
                            if (e.target.files) {
                                props.onChange(e.target.files)
                            }
                        }}
                        placeholder={props.placeholder}
                    />

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                        <path
                            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                    </svg>

                    <span>{props.label}</span>

                </div>

                {props.value &&
                <>
                    {Array.from(props.value).map((file) => {
                        return (
                            <small>{file.name}  <button type={'button'} className={'c-delete-input'} style={{left: '0', pointerEvents: 'none'}}>x</button></small>
                        )
                    })}
                </>
                }


            </label>

            <>{props.uploadedFiles?.map((uploadedFile) => {

                return (
                    <div>
                        <small style={{fontSize: '1rem', position: 'relative', display: 'inline-block'}}>
                            <a href={uploadedFile.url}>{uploadedFile.url}</a>

                            {(uploadedFile) &&
                            <button type={'button'} className={'c-delete-input'}
                                    onClick={() => props.deleteAction(uploadedFile)}>x</button>
                            }

                        </small>
                    </div>
                )
            })}
            </>


        </div>

    )

}

export default FormFiles
