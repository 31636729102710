import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {PassportInterface} from "../../../common/interfaces/PassportInterface";
import {PassportTypeInterface} from "../../../common/interfaces/PassportTypeInterface";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../../common/enums/RouteEnum";
import {setFilterSearchString, setFilterType} from "../PassportOverviewPageSlice";
import {GridLoader} from "react-spinners";
import {displayModuleByUserRole} from "../../../common/utils/displayModuleByUserRole";
import {setTrashPassport} from "../../../modals/trashPassportModal/trashPassportModalSlice";
import {setDuplicatePassport} from "../../../modals/duplicatePassportModal/duplicatePassportModalSlice";
import {
    IsFreeTrialOrganisation,
    IsMaxTrialTotalPassportsCreated,
    SetDisabledButtonClassIf
} from "../../../common/utils/freeTrialRestrictions";
import {FreeTrialHoverCloud} from "../../../common/components/general/FreeTrialHoverCloud";

export const PassportOverviewPassports = () => {

    const {passports, settings, loading} = useAppSelector(state => state.passportOverview);
    const {passportTypes} = useAppSelector(state => state.root.options);
    const {userMe} = useAppSelector(state => state.root);

    let createPassportBtnClass = SetDisabledButtonClassIf (
        IsMaxTrialTotalPassportsCreated(userMe),
        `btn btn--rounded btn--red`
    )

    let duplicatePassportBtnClass = SetDisabledButtonClassIf (
        IsFreeTrialOrganisation(userMe),
        'btn btn--black btn--circle'
    )


    const dispatch = useAppDispatch()

    if (passports && passports.data) {

        const {total} = passports

        return (
            <>

                <header className="c-app__view__header">
                    <h1>C_passport<sup>®</sup> overview ({total})</h1>
                </header>
                <form className="c-app__filter c-form--black" onSubmit={(e) => e.preventDefault()}>
                    <div className="c-form__row">
                        <div className="c-form__group c-form__group--horizontal">
                            <input
                                value={settings.filters.searchString}
                                onChange={(e) => {
                                    dispatch(setFilterSearchString(e.target.value))
                                }}
                                type="text" placeholder="Search passports"/>
                        </div>
                        <div className="c-form__group c-form__group--horizontal c-form__select">
                            <select
                                onChange={(e) => {
                                    dispatch(setFilterType(Number(e.target.value)))
                                }}
                                value={settings.filters.type}>
                                <option>All passports</option>
                                {passportTypes?.data?.map((passportType) => {
                                    return (<option key={'passport-type:' + passportType.id}
                                                    value={passportType.id}>{passportType.name}</option>)
                                })}
                            </select>
                            <img src="images/icons/arrow-down-black.svg" alt=""/>
                        </div>
                    </div>
                </form>

                {loading &&
                <div className={'c-loader'}>
                    <GridLoader color={'#E14343'}/>
                </div>
                }


                {Number(total) === 0 &&

                <div className="c-text c-text--small c-text--center"
                     style={{width: '100%', margin: '4rem 0', alignItems: 'flex-start'}}>
                    <p>
                        No passports found.
                    </p>
                    {displayModuleByUserRole('passportEdit', userMe) &&
                    <Link to={RouteEnum.PASSPORT_CREATE} className={createPassportBtnClass}>
                        <span>Create C_passport<sup>®</sup></span>
                        <FreeTrialHoverCloud text={'You have created the maximum amount of passports during this trial period. Please complete your account to create more passports.'}/>
                    </Link>
                    }
                </div>


                }

                {(Number(total) !== 0 && !loading) &&

                <div className="c-card" style={{minHeight: '30rem'}}>

                    {passports.data.map((passport: PassportInterface) => {

                        const passportType = passportTypes?.data?.find((passportType: PassportTypeInterface) => passportType.id === passport.typeId)

                        return (

                            <div className="c-card__single c-card__single--small" key={'passport:' + passport.id}>

                                <div className="c-card__single__info">
                                    <div className="c-card__single__info__text">
                                        <h3>{passport.name}</h3>
                                        {passportType && <span>{passportType.name}</span>}
                                    </div>
                                </div>

                                <div className="c-card__single__cta">
                                    <div className="c-card__single__cta__urls">
                                        <div>
                                            <Link to={RouteEnum.PASSPORT_DETAIL + '/' + passport.uuid}
                                                  className="btn btn--text"><span>View</span>
                                            </Link>

                                            {displayModuleByUserRole('passportEdit', userMe) &&
                                            <Link to={RouteEnum.PASSPORT_EDIT + '/' + passport.uuid}
                                                  className="btn btn--red btn--circle">
                                                <img src="/images/icons/edit-white.svg" alt=""/></Link>
                                            }

                                            {displayModuleByUserRole('passportEdit', userMe) &&
                                            <button className="btn btn--black btn--circle"
                                                    style={{marginLeft: '.5rem'}}
                                                    onClick={() => {
                                                        dispatch(setTrashPassport({
                                                            showPopup: true,
                                                            passport: passport
                                                        }))
                                                    }}
                                            >
                                                <img src="/images/icons/trash.svg" alt={'trash icon'}
                                                     style={{height: '2rem'}}/>
                                            </button>
                                            }

                                            {displayModuleByUserRole('passportEdit', userMe) &&
                                            <button className={duplicatePassportBtnClass}
                                                    style={{marginLeft: '.5rem'}}
                                                    onClick={() => {
                                                        dispatch(setDuplicatePassport({
                                                            showPopup: true,
                                                            passport: passport
                                                        }))
                                                    }}
                                            >

                                                <img src="/images/icons/icon-duplicate.svg" alt={'trash icon'}
                                                     style={{height: '2rem'}}/>

                                            </button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                }

            </>

        )
    } else return null

}
