import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    CompositionInterface,
    MaterialInterface,
    PassportGroupInterface,
    PassportInterface
} from "../../common/interfaces/PassportInterface";
import {apiOrganisationPassportCreate} from "../../common/api/organisation/passport/apiOrganisationPassportCreate";
import {apiOrganisationPassportUpdate} from "../../common/api/organisation/passport/apiOrganisationPassportUpdate";
import {apiOrganisationPassportList} from "../../common/api/organisation/passport/apiOrganisationPassportList";
import {LoadPassportAction} from "../../common/actions/LoadPassportAction";
import {AssemblyInterface} from "../../common/interfaces/AssemblyInterface";
import {apiOrganisationUploadDelete} from "../../common/api/organisation/upload/apiOrganisationUploadDelete";
import {UploadedFileInterface} from "../../common/interfaces/UploadedFileInterface";
import {getUserMe} from "../../app/rootReducer";
import {apiOrganisationGroupList} from "../../common/api/organisation/group/apiOrganisationGroupList";
import {apiOrganisationGroupUpdate} from "../../common/api/organisation/group/apiOrganisationGroupUpdate";
import {PassportImpactFiguresInterface} from "../../common/interfaces/PassportImpactFiguresInterface";
import {ImpactFigureCalculationTypeInterface} from "../../common/interfaces/ImpactFigureCalculationTypeInterface";
import {getManualImpactFigure} from "../../common/utils/PassportImpactFigures";

interface setManualPassportImpactFigureInteface {
    passportImpactFigure: PassportImpactFiguresInterface,
    passportImpactFigureCalculationTypes?: ImpactFigureCalculationTypeInterface[]
}

interface passportCreateInterface {
    activeStep: number,
    activeCompositionMaterialGroupId?: number
    passport: PassportInterface,
    initialPassportGroups?: PassportGroupInterface[], // Necessary due to limited create endpoint
    passportGroups?: PassportGroupInterface[],
    passports?: {
        data: PassportInterface[],
        pages?: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    groups?: {
        data: PassportGroupInterface[],
        pages: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    composition: CompositionInterface[],
    assemblies: AssemblyInterface[],
    parentAssemblies: AssemblyInterface[],
    customImpact: boolean,
    submitting: boolean,
    submitted: boolean,
    updateAblePassport: boolean
}

const initialState: passportCreateInterface = {
    passport: {
        sdgGoals: [],
        connectedPassports: [],
        score: 20,
        calculateEndOfUse: 0,
        calculateImpactFigures: 1,
        passportImpactFigures: []
    },
    composition: [],
    assemblies: [],
    parentAssemblies: [],
    activeStep: 1,
    activeCompositionMaterialGroupId: undefined,
    customImpact: true,
    submitting: false,
    submitted: false,
    updateAblePassport: false
}

export const passportList = createAsyncThunk(
    'passportCreate/passportList',
    async (_) => {
        const response = await apiOrganisationPassportList(9999)
        if (response && response.data && response.data.items && response.data.items.data) {
            return response.data.items
        }
    }
)

export const groupList = createAsyncThunk(
    'root/groupList',
    async (_) => {
        const response = await apiOrganisationGroupList(9999)


        if (response && response.data && response.data.items && response.data.items.data) {
            return response.data.items
        }
    }
)


export const submit = createAsyncThunk(
    'passportCreate/submit',
    async (_, {getState, dispatch}) => {

        const {passportCreate} = getState() as { passportCreate: passportCreateInterface };

        if (passportCreate.passport.uuid) {
            const response = await apiOrganisationPassportUpdate(passportCreate.passport, passportCreate.composition, passportCreate.assemblies, passportCreate.parentAssemblies)
            if (response && response.data && response.data.items && response.data.items.passport) {

                if (passportCreate.passportGroups) {

                    // find groups that current passport is present in
                    const currentGroups = passportCreate.groups?.data.filter((group) => {
                        return passportCreate.passportGroups?.find((passportGroup) => passportGroup.id === group.id)
                    })

                    if (currentGroups?.length) {

                        // Add passport to group
                        for (const group of currentGroups) {

                            const updatePassportGroups = {
                                ...group
                            } as any

                            updatePassportGroups.passports = updatePassportGroups.passports?.map((passport: any) => {
                                if (passport.id) {
                                    return passport.id
                                }
                                return 0
                            })

                            if (!updatePassportGroups.passports.includes(passportCreate.passport.id)) {
                                updatePassportGroups.passports.push(passportCreate.passport.id)
                            }

                            await apiOrganisationGroupUpdate(updatePassportGroups)

                        }

                    }

                    const removedGroups = passportCreate.initialPassportGroups?.filter((initialPassportGroup) => {
                        return !passportCreate.passportGroups?.includes(initialPassportGroup)
                    })


                    if (removedGroups?.length) {

                        // Remove passport to group
                        for (const group of removedGroups) {

                            const updatePassportGroups = {
                                ...group
                            } as any

                            updatePassportGroups.passports = updatePassportGroups.passports?.map((passport: any) => {
                                if (passport.id) {
                                    return passport.id
                                }
                                return 0
                            })

                            updatePassportGroups.passports = updatePassportGroups.passports?.filter((passportId: any) => {
                                return passportId !== passportCreate.passport.id
                            })

                            await apiOrganisationGroupUpdate(updatePassportGroups)

                        }
                    }


                }

                const passport = response.data.items.passport

                // Used to retrieve new passport count in user.organisation
                dispatch(getUserMe())

                return passport

            }

        } else {

            const response = await apiOrganisationPassportCreate(passportCreate.passport, passportCreate.composition, passportCreate.assemblies, passportCreate.parentAssemblies)

            if (response && response.data && response.data.items && response.data.items.passport) {
                return response.data.items.passport
            }
        }
    }
)

export const deleteUpload = createAsyncThunk(
    'passportCreate/deleteUpload',
    async ({name, id}: { name: string, id?: number }) => {

        if (id) {
            const response = await apiOrganisationUploadDelete(id)
            console.log(response)
        }
        return {key: name, id: id}


    }
)

export const load = createAsyncThunk(
    'passportCreate/load',
    LoadPassportAction
)

const passportCreateSlice = createSlice({
    name: 'passportCreate',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState)
        },
        changeCreateStep: (state, action: PayloadAction<number>) => {
            state.activeStep = action.payload
        },
        setActiveCompositionMaterialGroupId: (state, action: PayloadAction<number>) => {
            state.activeCompositionMaterialGroupId = action.payload
        },
        toggleCustomImpact: (state) => {
            state.customImpact = !state.customImpact
        },
        setCreatePassportData: (state, action: PayloadAction<PassportInterface>) => {
            state.submitted = false
            state.updateAblePassport = true

            state.passport = {
                ...state.passport,
                ...action.payload
            }
        },
        setEndUse: (state, action: PayloadAction<number>) => {
            state.submitted = false
            state.updateAblePassport = true

            if (action.payload) {
                state.passport.endOfUseSolution = {
                    ...state.passport.endOfUseSolution,
                    id: action.payload
                }
            }
        },
        setCompositions: (state, action: PayloadAction<number[]>) => {
            state.submitted = false
            state.updateAblePassport = true

            // Remove items
            state.composition = (state.composition && state.composition.filter((composition) => action.payload.find((materialGroupId) => materialGroupId === composition.materialGroupId)))

            // Add items
            action.payload.forEach((materialGroupId) => {
                if (state.composition && !state.composition.find((compositionItem) => compositionItem.materialGroupId === materialGroupId)) {
                    state.composition && state.composition.push({
                        materialGroupId: materialGroupId,
                        materials: []
                    })
                }
            })

            if (state.composition && state.composition.length) {
                state.activeCompositionMaterialGroupId = state.composition[state.composition.length - 1].materialGroupId
            }

        },

        setManualPassportImpactFigure: (state, action: PayloadAction<setManualPassportImpactFigureInteface>) => {
            state.submitted = false
            state.updateAblePassport = true

            if (action.payload) {
                const exisingManualFigures = getManualImpactFigure(state.passport, action.payload.passportImpactFigureCalculationTypes)
                const manualType = action.payload.passportImpactFigureCalculationTypes?.find((type) => type.code === 'manual')

                if (!exisingManualFigures) {

                    state.passport.passportImpactFigures?.push({
                        ...action.payload.passportImpactFigure,
                        passportImpactFigureCalculationTypeId: manualType?.id
                    })

                }

                if (exisingManualFigures) {

                    state.passport.passportImpactFigures = state.passport.passportImpactFigures?.map((passportImpactFigure) => {

                        if (passportImpactFigure.passportImpactFigureCalculationTypeId === manualType?.id) {

                            return {
                                ...passportImpactFigure,
                                ...action.payload.passportImpactFigure
                            }

                        } else return passportImpactFigure
                    })
                }
            }
        },

        setSdgGoal: (state, action: PayloadAction<number>) => {
            state.submitted = false
            state.updateAblePassport = true

            if (!state.passport.sdgGoals) {
                state.passport.sdgGoals = []
            }
            if (state.passport.sdgGoals?.includes(action.payload)) {
                state.passport.sdgGoals = state.passport.sdgGoals?.filter((sdgGoal) => sdgGoal !== action.payload)
            } else {
                state.passport.sdgGoals?.push(action.payload)
            }
        },

        setConnectedPassport: (state, action: PayloadAction<number>) => {
            if (!state.passport.connectedPassports) {
                state.passport.connectedPassports = []
            }
            if (state.passport.connectedPassports?.includes(action.payload)) {
                state.passport.connectedPassports = state.passport.connectedPassports?.filter((connectedPassport) => connectedPassport !== action.payload)
            } else {
                state.passport.connectedPassports?.push(action.payload)
            }

        },

        setCompositionData: (state, action: PayloadAction<CompositionInterface>) => {
            state.submitted = false
            state.updateAblePassport = true

            state.composition = state.composition && state.composition.map((composition) => {
                if (composition.materialGroupId === action.payload.materialGroupId) {

                    /** Checking if % isn't higher than 100% */

                    if (action.payload.compositionPercentage) {

                        let percentage = 0;

                        state.composition?.forEach((composition) => {

                            if (composition.materialGroupId !== action.payload.materialGroupId && composition.compositionPercentage) {
                                percentage += Number(composition.compositionPercentage)
                            }

                        })

                        if (percentage + Number(action.payload.compositionPercentage) > 100) {
                            return {
                                ...composition,
                                percentageWarning: true
                            }
                        }
                    }

                    /** End % check */

                    return {
                        ...composition,
                        ...action.payload,
                        percentageWarning: false
                    }
                } else return composition
            })
        },
        setCompositionMaterials: (state, action: PayloadAction<{ materialGroupId: number, materialIds: number[] }>) => {
            state.submitted = false
            state.updateAblePassport = true

            state.composition = state.composition && state.composition.map((composition) => {
                if (composition.materialGroupId === action.payload.materialGroupId) {

                    // Remove items
                    composition.materials = (composition.materials && composition.materials.filter((material) => action.payload.materialIds.find((materialId) => materialId === material.materialId)))

                    // Add items
                    action.payload.materialIds.forEach((materialId) => {
                        if (composition.materials && !composition.materials.find((materialItem) => materialItem.materialId === materialId)) {

                            composition.materials.push({
                                materialId: materialId
                            })
                        }
                    })

                    return composition

                } else return composition
            })

        },

        setCompositionMaterialData: (state, action: PayloadAction<{
            materialGroupId: number,
            material: MaterialInterface
        }>) => {
            state.submitted = false
            state.updateAblePassport = true

            state.composition = state.composition && state.composition.map((composition) => {
                if (composition.materialGroupId === action.payload.materialGroupId) {

                    composition.materials = composition.materials && composition.materials.map((material) => {

                        if (material.materialId === action.payload.material.materialId) {

                            /** Checking if % isn't higher than 100% */

                            if (action.payload.material.compositionPercentage) {

                                let percentage = 0;

                                composition.materials?.forEach((material) => {

                                    if (material.materialId !== action.payload.material.materialId && material.compositionPercentage) {
                                        percentage += parseInt(material.compositionPercentage)
                                    }

                                })

                                if (percentage + parseInt(action.payload.material.compositionPercentage) > 100) {
                                    return {
                                        ...material,
                                        percentageWarning: true
                                    }
                                }
                            }

                            /** End % check */

                            return {
                                ...material,
                                ...action.payload.material,
                                percentageWarning: false

                            }
                        } else return material
                    })

                    return composition

                } else return composition
            })
        },
        setGroups: (state, action: PayloadAction<number[]>) => {
            state.submitted = false
            state.updateAblePassport = true

            // Remove items
            state.passportGroups = (state.passportGroups && state.passportGroups.filter((passportGroup) => action.payload.find((groupId) => groupId === passportGroup.id)))

            // Add items
            action.payload.forEach((groupId) => {
                if (state.passportGroups && !state.passportGroups.find((group) => group.id === groupId)) {

                    const group = state.groups?.data.find((group) => group.id === groupId)

                    if (group) {
                        state.passportGroups.push(group)
                    }

                }
            })
        },
        setAssemblies: (state, action: PayloadAction<number[]>) => {
            state.submitted = false
            state.updateAblePassport = true

            // Remove items
            state.assemblies = (state.assemblies && state.assemblies.filter((assembly) => action.payload.find((assemblyId) => assemblyId === assembly.assemblyPassportId)))

            // Add items
            action.payload.forEach((assemblyId) => {
                if (state.assemblies && !state.assemblies.find((assemblyItem) => assemblyItem.assemblyPassportId === assemblyId)) {
                    state.assemblies && state.assemblies.push({
                        assemblyPassportId: assemblyId,
                        amount: 1
                    })
                }
            })
        },
        setAssemblyData: (state, action: PayloadAction<AssemblyInterface>) => {

            state.submitted = false
            state.updateAblePassport = true

            state.assemblies = state.assemblies.map((assembly) => {
                if (assembly.assemblyPassportId === action.payload.assemblyPassportId) {
                    return {
                        ...assembly,
                        ...action.payload
                    }
                } else return assembly
            })

        },
        setParentAssemblies: (state, action: PayloadAction<number[]>) => {
            state.submitted = false
            state.updateAblePassport = true

            // Remove items
            state.parentAssemblies = (state.parentAssemblies && state.parentAssemblies.filter((assembly) => action.payload.find((assemblyId) => assemblyId === assembly.assemblyPassportId)))

            // Add items
            action.payload.forEach((passportId) => {
                if (state.parentAssemblies && !state.parentAssemblies.find((parentAssemblyItem) => parentAssemblyItem.passportId === passportId)) {
                    state.parentAssemblies && state.parentAssemblies.push({
                        passportId: passportId,
                        amount: 1
                    })
                }
            })
        },
        setParentAssemblyData: (state, action: PayloadAction<AssemblyInterface>) => {
            state.submitted = false
            state.updateAblePassport = true

            state.parentAssemblies = state.parentAssemblies.map((parentAssembly) => {
                if (parentAssembly.passportId === action.payload.passportId) {
                    return {
                        ...parentAssembly,
                        ...action.payload
                    }
                } else return parentAssembly
            })
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state, action: PayloadAction<any>) => {
            state.passport.id = action.payload.passport.id
            state.passport.uuid = action.payload.passport.uuid
            state.submitting = false
            state.submitted = true
            state.updateAblePassport = false
        })
        builder.addCase(passportList.fulfilled, (state, action: PayloadAction<passportCreateInterface['passports']>) => {
            state.passports = action.payload
        })

        builder.addCase(groupList.fulfilled, (state, action: PayloadAction<passportCreateInterface['groups']>) => {
            state.groups = action.payload
        })

        builder.addCase(deleteUpload.fulfilled, (state, action: PayloadAction<{ key: string, id?: number }>) => {

            const filterFiles = (fileState: UploadedFileInterface[], id: number) => {
                return fileState?.filter((file) => file.id !== id)
            }
            switch (action.payload.key) {
                case('image'):
                    delete state.passport.image
                    delete state.passport.imageFileUpload
                    break;
                case('msdsFile'):
                    delete state.passport.msdsFile
                    delete state.passport.msdsFileUpload
                    break;
                case('reverseLogisticsInstructionFiles'):
                    if (action.payload.id && state.passport.reverseLogisticsInstructionFiles) {
                        state.passport.reverseLogisticsInstructionFiles = filterFiles(state.passport.reverseLogisticsInstructionFiles, action.payload.id)
                    } else {
                        delete state.passport.reverseLogisticsInstructionFiles
                        delete state.passport.reverseLogisticsInstructionFilesUpload
                    }

                    break;
                case('assessmentInstructionFiles'):
                    if (action.payload.id && state.passport.assessmentInstructionFiles) {
                        state.passport.assessmentInstructionFiles = filterFiles(state.passport.assessmentInstructionFiles, action.payload.id)
                    } else {
                        delete state.passport.assessmentInstructionFiles
                        delete state.passport.assessmentInstructionFilesUpload
                    }
                    break;
                case('disassemblyInstructionFiles'):
                    if (action.payload.id && state.passport.disassemblyInstructionFiles) {
                        state.passport.disassemblyInstructionFiles = filterFiles(state.passport.disassemblyInstructionFiles, action.payload.id)
                    } else {
                        delete state.passport.disassemblyInstructionFiles
                        delete state.passport.disassemblyInstructionFilesUpload
                    }
                    break;
                case('recyclingInstructionFiles'):
                    if (action.payload.id && state.passport.recyclingInstructionFiles) {
                        state.passport.recyclingInstructionFiles = filterFiles(state.passport.recyclingInstructionFiles, action.payload.id)
                    } else {
                        delete state.passport.recyclingInstructionFiles
                        delete state.passport.recyclingInstructionFilesUpload
                    }
                    break;
                case('nextUseApplicationDescriptionFiles'):
                    if (action.payload.id && state.passport.nextUseApplicationDescriptionFiles) {
                        state.passport.nextUseApplicationDescriptionFiles = filterFiles(state.passport.nextUseApplicationDescriptionFiles, action.payload.id)
                    } else {
                        delete state.passport.nextUseApplicationDescriptionFiles
                        delete state.passport.nextUseApplicationDescriptionFilesUpload
                    }
                    break;
                case('appendicesFiles'):
                    if (action.payload.id && state.passport.appendicesFiles) {
                        state.passport.appendicesFiles = filterFiles(state.passport.appendicesFiles, action.payload.id)
                    } else {
                        delete state.passport.appendicesFiles
                        delete state.passport.appendicesFilesUpload
                    }
                    break;
            }


        })

        builder.addCase(load.pending, (state) => {
            state.activeStep = 2
        })
        builder.addCase(load.fulfilled, (state, action: PayloadAction<any>) => {

            state.passport = action.payload.passport
            state.passportGroups = action.payload.passportGroups
            state.initialPassportGroups = action.payload.passportGroups
            state.updateAblePassport = false

            if (action.payload.composition && action.payload.composition.length) {
                state.composition = action.payload.composition
                state.activeCompositionMaterialGroupId = action.payload.composition[0].materialGroupId
            }

            if (action.payload.assemblies && action.payload.assemblies.length) {
                state.assemblies = action.payload.assemblies
            }

            if (action.payload.parentAssemblies && action.payload.parentAssemblies.length) {
                state.parentAssemblies = action.payload.parentAssemblies
            }
        })
    }
})

export const {
    setCreatePassportData,
    changeCreateStep,
    setEndUse,
    setCompositions,
    setCompositionData,
    setActiveCompositionMaterialGroupId,
    setCompositionMaterials,
    setCompositionMaterialData,
    setSdgGoal,
    setConnectedPassport,
    reset,
    setGroups,
    setAssemblies,
    setAssemblyData,
    setParentAssemblies,
    setParentAssemblyData,
    setManualPassportImpactFigure,
} = passportCreateSlice.actions

export const passportCreateReducer = passportCreateSlice.reducer
