import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {splitTextByUrls} from "../../utils/splitTextByUrls";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    translations?: TranslationsInterface
}


export const PassportExtraInformation = (props: Props) => {

    const {
        reverseLogisticsInstructionFiles,
        reverseLogisticsInstructionFilesComment,
        disassemblyInstructionFiles,
        disassemblyInstructionFilesComment,
        assessmentInstructionFiles,
        assessmentInstructionFilesComment,
        recyclingInstructionFiles,
        recyclingInstructionFilesComment,
        appendicesFiles,
        appendicesFilesComment,
        nextUseApplicationDescriptionFiles,
        nextUseApplicationDescriptionFilesComment
    } = props.passport

    const displayComment = (comment: string | undefined) => {

        const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;
        let parts = splitTextByUrls(comment ?? '', urlRegex);
        parts = parts.filter((part) => part && part !== '' && part !== ' ');

        if (parts.length > 0) {
            const partsElements = parts.map((part) => {
                if (part.match(urlRegex)) {

                    return (
                        <a  style={{color: '#000', paddingRight: 0, paddingLeft: 0, marginLeft: '0.2rem', marginRight: '0.1rem'}}
                            target="_blank"
                            rel="noreferrer"
                            href={part}>{part}
                        </a>
                    )
                }
                 return <span style={{paddingRight: 0, paddingLeft: 0}}>{part}</span>
            })

            return <p>{partsElements}</p>
        }
        return <p>{comment}</p>
    }

    return (
        <div className="c-passport__tabs__content__wrapper c-files">

            {!!props.passport.msdsFile &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.msds}</span>
                    <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                       href={props.passport.msdsFile?.url}>{props.translations?.document}</a>
                </div>
            }

            {!!props.passport.webUrl &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.website}</span>
                    <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                       href={props.passport.webUrl}>{props.passport.webUrl}</a>
                </div>
            }

            {!!(reverseLogisticsInstructionFiles?.length || reverseLogisticsInstructionFilesComment) &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.reverseLogisticsInstructions}</span>
                    {reverseLogisticsInstructionFiles?.map((file) => {
                        return (
                            <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                               href={file.url}>{file.url}</a>
                        )
                    })}

                    {!!reverseLogisticsInstructionFilesComment && displayComment(reverseLogisticsInstructionFilesComment)}


                </div>
            }

            {!!(assessmentInstructionFiles?.length || assessmentInstructionFilesComment) &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.assessmentInstruction}</span>
                    {assessmentInstructionFiles?.map((file) => {
                        return (
                            <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                               href={file.url}>{file.url}</a>
                        )
                    })}

                    {!!assessmentInstructionFilesComment && displayComment(assessmentInstructionFilesComment)}

                </div>
            }

            {!!(disassemblyInstructionFiles?.length || disassemblyInstructionFilesComment) &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.disassemblyInstruction}</span>

                    {disassemblyInstructionFiles?.map((file) => {
                        return (
                            <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                               href={file.url}>{file.url}</a>
                        )
                    })}

                    {!!disassemblyInstructionFilesComment && displayComment(disassemblyInstructionFilesComment)}

                </div>
            }

            {!!(recyclingInstructionFiles?.length || recyclingInstructionFilesComment) &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.recyclingInstruction}</span>

                    {recyclingInstructionFiles?.map((file) => {
                        return (
                            <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                               href={file.url}>{file.url}</a>
                        )
                    })}

                    {!!recyclingInstructionFilesComment && displayComment(recyclingInstructionFilesComment)}


                </div>
            }

            {(!!nextUseApplicationDescriptionFiles?.length || !!nextUseApplicationDescriptionFilesComment) &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.nextUseApplicationDescription}</span>

                    {nextUseApplicationDescriptionFiles?.map((file) => {
                        return (
                            <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                               href={file.url}>{file.url}</a>
                        )
                    })}

                    {!!nextUseApplicationDescriptionFilesComment && displayComment(nextUseApplicationDescriptionFilesComment)}

                </div>
            }

            {(!!appendicesFiles?.length || appendicesFilesComment) &&
                <div className="c-passport__tabs__content__single">
                    <span className={'text-color-copy'}>{props.translations?.appendices}</span>

                    {appendicesFiles?.map((file) => {
                        return (
                            <a className={'text-color-copy'} target="_blank" rel="noreferrer"
                               href={file.url}>{file.url}</a>
                        )
                    })}

                    {!!appendicesFilesComment && displayComment(appendicesFilesComment)}


                </div>
            }
        </div>
    )

} 