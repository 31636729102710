import {useAppSelector} from "../../../app/hooks";
import {PassportTypeInterface} from "../../interfaces/PassportTypeInterface";
import {WeightTypeInterface} from "../../interfaces/WeightTypeInterface";
import {AmountTypeInterface} from "../../interfaces/AmountTypeInterface";
import {FormatDominantMaterialGroupsToString} from "../../utils/FormatDominantMaterialGroupsToString";
import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {PassportPreferenceInterface} from "../../interfaces/PassportPreferenceInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {IsFreeTrialOrganisation} from "../../utils/freeTrialRestrictions";
import {EndOfUseSelector} from "../general/EndOfUseSelector";


interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    passportPreference?: PassportPreferenceInterface,
    translations?: TranslationsInterface,
    activeImpactFigureTypeId?: number,
    overruleEndUseId?: number,
    changeEndUseId?: (endUseId: number) => void,
}

export const PassportInfo = (props: Props) => {

    const {passportTypes, weightTypes, amountTypes} = useAppSelector(state => state.root.options);

    const passportType = passportTypes?.data?.find((passportType: PassportTypeInterface) => passportType.id === props.passport.typeId)
    const weightType = weightTypes?.data?.find((weightType: WeightTypeInterface) => weightType.id === props.passport.weightTypeId)
    const amountType = amountTypes?.data?.find((amountType: AmountTypeInterface) => amountType.id === props.passport.amountTypeId)
    const {userMe} = useAppSelector(state => state.root);

    const dominantMaterialString = FormatDominantMaterialGroupsToString(props.composition)
    const endUseCalculatedList = props.passport.endOfUseCalculatedList

    const {translations} = props

    return (
        <div className="c-dashboard__card background-color-blocks c-passport__info">

            <div className="c-passport__info__intro">

                {props.passport.image && (!props.passportPreference || props.passportPreference.image) &&
                    <div className="c-passport__info__intro__image">
                        <img src={props.passport.image?.uploadPath} alt=""/>
                    </div>
                }

                {(!props.passportPreference || props.passportPreference.titleAndDescription) &&
                    <div className="c-passport__info__intro__text">
                        <div>
                            <h1 className={'text-color-highlight'}>{props.passport.name}</h1>
                            {passportType &&
                                <div className="c-tag c-tag--black">{passportType.name}</div>
                            }
                        </div>
                        {IsFreeTrialOrganisation(userMe) &&
                            <div className="c-passport__info__intro__text__trial-disclaimer">
                                <img src="/images/icons/info-red.svg" alt=""/>
                                <p>
                                    This is a public Cirmar passport made with a Free-trial account and for internal use
                                    only.
                                    External use of this public passport is considered illegal.
                                </p>
                            </div>
                        }
                        <p className={'text-color-copy'}>{props.passport.description}</p>
                    </div>
                }

            </div>


            <div className="c-passport__info__blocks">
                <div className="c-passport__info__blocks__row">
                    {!!props.passport.definedUsePeriod &&
                        <div className="c-passport__info__blocks__single">
                            <span className={'text-color-highlight'}>{translations?.definedUsePeriod}</span>
                            <span className={'text-color-copy'}>{props.passport.definedUsePeriod}</span>
                        </div>
                    }
                    {!!props.composition.length && (!props.passportPreference || props.passportPreference?.dominantMaterialGroups) &&
                        <div className="c-passport__info__blocks__single">
                            <span className={'text-color-highlight'}>{translations?.dominantMaterialGroups}</span>
                            <span className={'text-color-copy'}>{dominantMaterialString}</span>
                        </div>
                    }

                    {props.passport.nextUseApplication && (!props.passportPreference || props.passportPreference?.nextUseApplication) &&
                        <div className="c-passport__info__blocks__single">
                            <span className={'text-color-highlight'}>{translations?.nextUseApplication}</span>
                            <span className={'text-color-copy'}>{props.passport.nextUseApplication}</span>
                        </div>
                    }

                </div>
                <div className="c-passport__info__blocks__row">
                    {(!props.passportPreference || props.passportPreference?.endOfUseTreatment) &&
                        <div className="c-passport__info__blocks__single">

                            <div className="c-passport__info__blocks__row--no-border">
                                <div className="c-passport__info__blocks">
                                    <span className={'text-color-highlight'}>{translations?.endOfUseTreatment}</span>
                                    <span className={'text-color-copy'}>{endUseCalculatedList}</span>
                                </div>

                                {props.changeEndUseId &&
                                    <EndOfUseSelector
                                        activeImpactFigureTypeId={props.activeImpactFigureTypeId}
                                        passport={props.passport}
                                        overruleEndUseId={props.overruleEndUseId}
                                        changeEndUseId={(endUseId) => {
                                            props.changeEndUseId?.(endUseId)
                                        }}
                                    />
                                }

                            </div>
                        </div>
                    }

                    {(props.passport.weight && weightType) &&
                        <div className="c-passport__info__blocks__single">
                            <span className={'text-color-highlight'}>{translations?.weight}</span>
                            <span className={'text-color-copy'}>{props.passport.weight} {weightType.name}</span>
                        </div>
                    }

                    {(props.passport.amount && amountType) && (!props.passportPreference || props.passportPreference?.quantity) &&
                        <div className="c-passport__info__blocks__single">
                            <span className={'text-color-highlight'}>{translations?.quantity}</span>
                            <span className={'text-color-copy'}>{props.passport.amount} {amountType.name}</span>
                        </div>
                    }

                    {(!props.passportPreference || props.passportPreference?.msds) &&
                        <div className="c-passport__info__blocks__single">
                            <span className={'text-color-highlight'}>{translations?.msds}</span>
                            {!!props.passport.msdsFile &&
                                <span className={'text-color-copy'}>{translations?.yes}</span>}
                            {!props.passport.msdsFile && <span className={'text-color-copy'}>{translations?.no}</span>}
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}