import {HowToCloud} from "../howTo/HowToCloud";

interface InputProps {
    howToCode?: string,
    label?: string,
    name?: string,
    required?: boolean,
    onChange: (value: File) => void,
    placeholder?: string,
    value?: File,
    currentPath?: string,
    modal?: string,
    loading?: boolean,
    defaultLabel?: boolean,
    accept: string,
    deleteAction?: any
}

const FormFile = (props: InputProps) => {


    return (
        <div className="c-form__group" style={{position: 'relative'}}>

            {(props.defaultLabel || props.howToCode) && <label style={{
                margin: '0 0 -2rem 0'
            }}>{props.label} {props.howToCode && <HowToCloud code={props.howToCode}/>}</label>}

            {(props.deleteAction && props.currentPath) &&
            <button type={'button'} className={'c-delete-input'} onClick={() => props.deleteAction()}>x</button>
            }

            <label className={'inputfile'}>

                <div className={'content'}>

                    <input
                        accept={props.accept}
                        disabled={props.loading}
                        name={props.name}
                        required={props.required}
                        type={'file'}
                        onChange={(e) => {
                            if (e.target.files) {
                                props.onChange(e.target.files[0])
                            }
                        }}
                        placeholder={props.placeholder}
                    />

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                        <path
                            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                    </svg>

                    <span>{props.label}</span>

                </div>

                {props.value ?
                    <small>{props.value.name}</small>
                    :
                    <>{props.currentPath &&
                    <small style={{fontSize: '1rem'}}><a href={props.currentPath}>{props.currentPath}</a></small>}</>
                }

            </label>


        </div>

    )

}

export default FormFile