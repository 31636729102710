import {changeCreateStep, setCreatePassportData} from "../passportCreateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import FormInput from "../../../common/components/form/FormInput";
import FormFile from "../../../common/components/form/FormFile";
import {useEffect} from "react";
import {deleteUpload} from "../passportCreateSlice";
import FormFiles from "../../../common/components/form/FormFIles";
import {UploadedFileInterface} from "../../../common/interfaces/UploadedFileInterface";

export const PassportCreateExtraInformation = () => {

    const dispatch = useAppDispatch()
    const {passport} = useAppSelector(state => state.passportCreate);

    useEffect(() => {
            window.scrollTo(0, 0)
        }, []
    );

    return (
        <div className="c-app__create__primary">
            <header className="c-app__create__header">
                <small>Step 5/6</small>
                <h1>Extra information</h1>
                <p>
                    For a complete passport, please fill in these requirements.
                </p>
            </header>
            <form onSubmit={(e) => {
                e.preventDefault()
                dispatch(changeCreateStep(6))
            }} className="c-form c-form--black">
                <div className="c-form__row c-form__row--half">

                    <FormInput
                        howToCode={'pp-webpage'}
                        label={'Webpage (url)'}
                        onChange={(value) => dispatch(setCreatePassportData({webUrl: value + ''}))}
                        type={'url'}
                        value={passport.webUrl}
                    />

                    <FormFile
                        howToCode={'pp-image'}
                        deleteAction={() => dispatch(deleteUpload({name: 'image', id: passport.image?.id}))}
                        accept={'.jpg, .jpeg, .png'}
                        onChange={(value) => dispatch(setCreatePassportData({imageFileUpload: value}))}
                        value={passport.imageFileUpload}
                        currentPath={passport.image?.uploadPath}
                        label={'Image of component'}
                    />

                </div>

                <div className="c-form__row">

                    <FormFiles
                        deleteAction={(file: UploadedFileInterface) => dispatch(deleteUpload({
                            name: 'reverseLogisticsInstructionFiles',
                            id: file?.id
                        }))}
                        accept={'.pdf'}
                        onChange={(value) => dispatch(setCreatePassportData({reverseLogisticsInstructionFilesUpload: value}))}
                        value={passport.reverseLogisticsInstructionFilesUpload}
                        uploadedFiles={passport.reverseLogisticsInstructionFiles}
                        label={'Reverse logistics instruction'}
                    />


                </div>

                <div className={'c-form__row'}>
                    <FormInput
                        placeholder={'Comment'}
                        onChange={(value) => dispatch(setCreatePassportData({reverseLogisticsInstructionFilesComment: value + ''}))}
                        type={'url'}
                        value={passport.reverseLogisticsInstructionFilesComment}
                    />
                </div>

                <div className="c-form__row">

                    <FormFiles
                        deleteAction={(file: UploadedFileInterface) => dispatch(deleteUpload({
                            name: 'assessmentInstructionFiles',
                            id: file?.id
                        }))}
                        accept={'.pdf'}
                        onChange={(value) => dispatch(setCreatePassportData({assessmentInstructionFilesUpload: value}))}
                        value={passport.assessmentInstructionFilesUpload}
                        uploadedFiles={passport.assessmentInstructionFiles}
                        label={'Assessment instruction'}
                    />
                </div>

                <div className={'c-form__row'}>
                    <FormInput
                        placeholder={'Comment'}
                        onChange={(value) => dispatch(setCreatePassportData({assessmentInstructionFilesComment: value + ''}))}
                        type={'url'}
                        value={passport.assessmentInstructionFilesComment}
                    />
                </div>

                <div className="c-form__row">
                    <FormFiles
                        deleteAction={(file: UploadedFileInterface) => dispatch(deleteUpload({
                            name: 'disassemblyInstructionFiles',
                            id: file?.id
                        }))}
                        accept={'.pdf'}
                        onChange={(value) => dispatch(setCreatePassportData({disassemblyInstructionFilesUpload: value}))}
                        value={passport.disassemblyInstructionFilesUpload}
                        uploadedFiles={passport.disassemblyInstructionFiles}
                        label={'Disassembly instruction'}
                    />
                </div>

                <div className={'c-form__row'}>
                    <FormInput
                        placeholder={'Comment'}
                        onChange={(value) => dispatch(setCreatePassportData({disassemblyInstructionFilesComment: value + ''}))}
                        type={'url'}
                        value={passport.disassemblyInstructionFilesComment}
                    />
                </div>

                <div className="c-form__row">
                    <FormFiles
                        deleteAction={(file: UploadedFileInterface) => dispatch(deleteUpload({
                            name: 'recyclingInstructionFiles',
                            id: file?.id
                        }))}
                        accept={'.pdf'}
                        onChange={(value) => dispatch(setCreatePassportData({recyclingInstructionFilesUpload: value}))}
                        value={passport.recyclingInstructionFilesUpload}
                        uploadedFiles={passport.recyclingInstructionFiles}
                        label={'Recycling instruction'}
                    />
                </div>

                <div className={'c-form__row'}>
                    <FormInput
                        placeholder={'Comment'}
                        onChange={(value) => dispatch(setCreatePassportData({recyclingInstructionFilesComment: value + ''}))}
                        type={'url'}
                        value={passport.recyclingInstructionFilesComment}
                    />
                </div>

                <div className="c-form__row">

                    <FormFiles
                        deleteAction={(file: UploadedFileInterface) => dispatch(deleteUpload({
                            name: 'nextUseApplicationDescriptionFiles',
                            id: file?.id
                        }))}
                        accept={'.pdf'}
                        onChange={(value) => dispatch(setCreatePassportData({nextUseApplicationDescriptionFilesUpload: value}))}
                        value={passport.nextUseApplicationDescriptionFilesUpload}
                        uploadedFiles={passport.nextUseApplicationDescriptionFiles}
                        label={'Next use application description'}
                    />
                </div>

                <div className={'c-form__row'}>
                    <FormInput
                        placeholder={'Comment'}
                        onChange={(value) => dispatch(setCreatePassportData({nextUseApplicationDescriptionFilesComment: value + ''}))}
                        type={'url'}
                        value={passport.nextUseApplicationDescriptionFilesComment}
                    />
                </div>

                <div className="c-form__row">
                    <FormFiles
                        deleteAction={(file: UploadedFileInterface) => dispatch(deleteUpload({
                            name: 'appendicesFile',
                            id: file?.id
                        }))}
                        accept={'.pdf'}
                        onChange={(value) => dispatch(setCreatePassportData({appendicesFilesUpload: value}))}
                        value={passport.appendicesFilesUpload}
                        uploadedFiles={passport.appendicesFiles}
                        label={'Appendices'}
                    />
                </div>

                <div className={'c-form__row'}>
                    <FormInput
                        placeholder={'Comment'}
                        onChange={(value) => dispatch(setCreatePassportData({appendicesFilesComment: value + ''}))}
                        type={'url'}
                        value={passport.appendicesFilesComment}
                    />
                </div>

                <button type={'submit'} className="btn btn--large btn--margin btn--width btn--rounded btn--red">
                    <span>Stap 6: connect C_passport<sup>®</sup></span>
                </button>


            </form>

        </div>
    )

}
