export const ApiEnum = {
    AUTH: process.env.REACT_APP_BASE_URL + '/api/auth',
    COUNTRY: process.env.REACT_APP_BASE_URL + '/api/country',
    MATERIAL: process.env.REACT_APP_BASE_URL + '/api/material',
    MEASUREMENT: process.env.REACT_APP_BASE_URL + '/api/measurement',
    NEXT_USE: process.env.REACT_APP_BASE_URL + '/api/next-use',
    END_USE: process.env.REACT_APP_BASE_URL + '/api/end-use',
    ORGANISATION_ADDRESS: process.env.REACT_APP_BASE_URL + '/api/organisation/address',
    ORGANISATION_INFO: process.env.REACT_APP_BASE_URL + '/api/organisation/info',
    ORGANISATION_PASSPORT: process.env.REACT_APP_BASE_URL + '/api/organisation/passport',
    ORGANISATION_GROUP: process.env.REACT_APP_BASE_URL + '/api/organisation/passport-group',
    ORGANISATION_DASHBOARD: process.env.REACT_APP_BASE_URL + '/api/organisation/dashboard',
    ORGANISATION_USER: process.env.REACT_APP_BASE_URL + '/api/organisation/user',
    ORGANISATION_UPLOAD: process.env.REACT_APP_BASE_URL + '/api/organisation/upload',
    ORGANISATION_COCKPIT: process.env.REACT_APP_BASE_URL + '/api/organisation/cockpit',
    SDG: process.env.REACT_APP_BASE_URL + '/api/sdg-goal',
    SUBSCRIPTION: process.env.REACT_APP_BASE_URL + '/api/subscription',
    HOW_TO: process.env.REACT_APP_BASE_URL + '/api/how-to',
    TRANSLATION: process.env.REACT_APP_BASE_URL + '/api/translation',
    WEBSITE_COCKPIT: process.env.REACT_APP_WP_URL + '/wp-json/v1',
    PASSPORT_IMPACT_FIGURE_CALCULATION_TYPES: process.env.REACT_APP_BASE_URL + '/api/passport-impact-figure-calculation-type',
}
