import {HowToCloud} from "../howTo/HowToCloud";

interface Option {
    label: string
    value: number | string
}

interface SelectProps {
    label?: string
    optional?: boolean
    onChange: (value: string) => void
    defaultValue?: string
    value?: number | string
    options?: Option[]
    howToCode?: string
    required?: boolean
}

const FormSelect = (props: SelectProps) => {

    return (
        <div className="c-form__group c-form__select">

            {(props.label || props.required || props.howToCode) &&
                <label htmlFor="">{props.label} {props.optional && <small>(optioneel)</small>} {props.howToCode &&
                    <HowToCloud code={props.howToCode}/>}</label>
            }


            <select value={props.value} onChange={(e) => props.onChange(e.target.value)}>
                <option selected={!props.value}>{props.defaultValue}</option>
                {props.options && props.options.reverse().map((mappedOption: Option) => {
                    return (
                        <option value={mappedOption.value}
                                key={'buildYear-' + mappedOption.value}>{mappedOption.label}</option>
                    )
                })}
            </select>

            <img src="/images/icons/arrow-down-black.svg" alt=""/>

        </div>
    )
}

export default FormSelect